<template>
	<section class="auth">
		<div class="columns">
			<div v-if="!isMobile" class="column is-two-fifths is-hidden-mobile p-0">
				<div class="auth__image"></div>
			</div>
			<div class="column auth__content">
				<slot>
					<router-view />
				</slot>
			</div>
		</div>
	</section>
</template>

<script>
import image from '../assets/images/background/login.png'
import { responsive } from '@/mixins/responsive'

export default {
	name: 'Layout',
	mixins: [responsive],
	data() {
		return {
			image: image
		}
	},
    metaInfo () {
      return {
        title: this.$tc(this.$route.meta.title)
      }
    }
}
</script>
