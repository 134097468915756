<template>
	<h1 class="logo text-center" v-bind="$attrs">
		<img src="@/assets/images/logo.png" :width="width" :alt="title" />
	</h1>
</template>

<script>
export default {
	name: 'Logo',
	props: {
		width: {
			type: String,
			default: '145px'
		},
		title: {
			type: String,
			default: process.env.VUE_APP_NAME
		}
	}
}
</script>
